




import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'TableItems',
})
export default class TableItems extends Vue {}
